import React from 'react';

import { EEyebrowSize, EEyebrowVariant, Eyebrow } from '../../atoms/Eyebrow';

export type TNewsletterProps = {
  title?: string;
  description?: string;
  newsletterFormComponent?: React.ReactNode;
};

export const Newsletter: React.FC<TNewsletterProps> = ({
  title,
  description,
  newsletterFormComponent,
}) => {
  return (
    <div className='flex w-full flex-col gap-6 lg:w-[400px] xl:w-auto xl:min-w-[432px] xl:flex-1 2xl:min-w-[400px]'>
      {title && (
        <h3 className='inline-flex w-full items-center justify-start border-b border-surface-200 pb-3'>
          <Eyebrow
            variant={EEyebrowVariant.TextTracking}
            size={EEyebrowSize.Medium}
            className='text-interface-500'
          >
            {title}
          </Eyebrow>
        </h3>
      )}
      {description && (
        <p className='w-full text-sm leading-[1.488rem] text-interface-800 xl:max-w-[26rem]'>
          {description}
        </p>
      )}
      {newsletterFormComponent}
    </div>
  );
};
