import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import { Icon } from '../../atoms/Icon';
import { EIconBottomPadding, EIconSize } from '../../atoms/Icon/Icon.constants';
import {
  ETitlePartsPosition,
  ETitlePartsSize,
  TitlePart,
} from '../../atoms/TitlePart';
import { ETitlePartsTheme } from '../../atoms/TitlePart/TitlePart.constants';

export type TSupportCardProps = {
  title: React.JSX.Element;
  icon: ImageProps | null;
  isOnline: boolean;
  className?: string;
  tooltip?: string;
};

const SupportCard: React.FC<TSupportCardProps> = ({
  title,
  icon,
  isOnline,
  className,
  tooltip,
}) => {
  return (
    <div
      className={clsx(
        'relative inline-flex h-[11.5rem] w-[8.5rem] flex-col items-center justify-center gap-2 rounded-3xl bg-white px-4 py-10',
        className,
      )}
      title={tooltip}
    >
      {isOnline && (
        <div className='absolute end-4 top-4 size-2 rounded-full bg-success'></div>
      )}
      {icon && (
        <div className='flex flex-col items-start justify-start gap-2'>
          <Icon
            size={EIconSize.L}
            bottomPadding={EIconBottomPadding.No}
            icon={icon}
          />
        </div>
      )}
      <div className='inline-flex items-start justify-center gap-2 self-stretch'>
        <TitlePart
          size={ETitlePartsSize.Xs}
          position={ETitlePartsPosition.Center}
          theme={ETitlePartsTheme.Dark}
          className='line-clamp-3 text-interface-1000 hover:text-brand-500'
        >
          {title}
        </TitlePart>
      </div>
    </div>
  );
};

export default SupportCard;
