import {
  EInputRounded,
  EInputVariant,
  RichText,
  toImageProps,
  toLinkProps,
} from '@front/shared/ds';
import { FooterV2 } from '@front/shared/ds/FooterV2';
import { Footer as TFooter, Locale } from '@shared/master-types';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { prepareSocialMedia } from './prepareSocialMedia';
import { usePrepareActions } from '../../../features/actions/useActions.hook';
import { NewsletterForm } from '../../forms/NewsletterForm/NewsletterForm';

export type TFooterWithSitemapConnectedConnectedProps = Extract<
  Required<TFooter>['footer'][number],
  { blockType: 'footer-v2' }
> & {
  locales?: Locale[];
};

const FooterV2Connected: React.FC<
  TFooterWithSitemapConnectedConnectedProps
> = props => {
  const {
    newsletter,
    socials,
    awards,
    linksGroups,
    leftLinks,
    rightLinks,
    moreText,
    contacts,
    copyright,
    description,
    legalText,
    supportCard,
    iconStyle,
  } = props;

  const router = useRouter();
  const currentLocale = String(router.query.locale);

  const processLinks = (
    leftLink: NonNullable<
      Required<Required<TFooterWithSitemapConnectedConnectedProps>['leftLinks']>
    >[number],
  ): {
    title: string;
    linkProps: LinkProps;
  } => ({
    title: leftLink.link[0].text,
    linkProps: toLinkProps(currentLocale, leftLink.link[0]),
  });

  const rightLinksArray = rightLinks?.map(processLinks) || [];
  const leftLinksArray = leftLinks?.map(processLinks) || [];
  const bottomContacts = (contacts || []).map(contact => ({
    text: contact.text,
    url: contact.url,
  }));

  const bottomPrepared = {
    moreText: moreText,
    rightLinks: rightLinksArray,
    leftLinks: leftLinksArray,
    contacts: bottomContacts,
    copyright: copyright,
    description: description,
    legalText: legalText,
  };

  const awardsPrepared = {
    title: awards?.title,
    list: awards?.yearList?.map(year => {
      return {
        listTitle: year.title,
        listSlides: year.awardsList?.map(slide => ({
          title: slide.title,
          id: slide.id,
          description: slide.description,
          logo: toImageProps(slide.icon),
          link:
            slide.link && slide.link[0]
              ? toLinkProps(currentLocale, slide.link[0])
              : null,
        })),
      };
    }),
  };

  const prepareActions = usePrepareActions();

  const linksPrepared = linksGroups?.map(linkGroup => ({
    title: linkGroup.title || '',
    menuItems: prepareActions(linkGroup.menuItems).map(action => ({ action })),
  }));

  const socialsPrepared = prepareSocialMedia({ iconStyle, socials });

  const supportCardsPrepared = supportCard?.map(card => {
    const [action] = prepareActions([card]);
    return {
      title: <RichText content={card.title} />,
      icon: toImageProps(card.icon),
      isOnline: card.onlineIndicator === 'yes',
      tooltip: card.tooltip,
      action,
    };
  });

  const newsletterItem = newsletter?.[0];
  const newsletterPrepared = newsletterItem
    ? {
        title: newsletterItem.title,
        description: newsletterItem.description,
        newsletterFormComponent: (
          <NewsletterForm
            formId={newsletterItem.formId}
            submitButtonText={newsletterItem.submitButtonText}
            confirmationMessage={newsletterItem.confirmationMessage}
            inputVariant={EInputVariant.Secondary}
            inputRounded={EInputRounded.Full}
            buttonRounded
            emailRequiredText={newsletterItem.emailRequiredText}
            emailInvalidText={newsletterItem.emailInvalidText}
            resubscribeText={newsletterItem.resubscribeText}
            additionalText={newsletterItem.additionalText}
            buttonClassName='border-2 text-lg !leading-normal border-control-250 md:w-auto shrink-0'
            inputClassName='placeholder:text-interface-500 placeholder:text-lg px-2 overflow-hidden truncate text-ellipsis'
          />
        ),
      }
    : null;

  return (
    <FooterV2
      links={linksPrepared}
      bottom={bottomPrepared}
      awards={awardsPrepared}
      socials={socialsPrepared}
      newsletter={newsletterPrepared}
      supportCards={supportCardsPrepared}
    />
  );
};

export default FooterV2Connected;
