import React from 'react';

import { EEyebrowSize, EEyebrowVariant, Eyebrow } from '../../atoms/Eyebrow';
import { ESocialMediaIconStyle } from '../../atoms/SocialMedia';
import {
  SocialMediaList,
  TSocialMediaListProps,
} from '../../atoms/SocialMediaList';

export type TSocialsProps = {
  title?: string;
  description?: React.JSX.Element | null;
  socialMedia?: TSocialMediaListProps['companyLinks'];
  iconStyle?: ESocialMediaIconStyle;
};

export const Socials: React.FC<TSocialsProps> = ({
  title,
  description,
  socialMedia,
  iconStyle = ESocialMediaIconStyle.Original,
}) => {
  return (
    <div className='w-full lg:w-[400px] xl:w-auto xl:min-w-[432px] xl:flex-1 2xl:min-w-[296px]'>
      {title && (
        <div className='flex items-center justify-start border-b border-surface-200 pb-3'>
          <Eyebrow
            variant={EEyebrowVariant.TextTracking}
            size={EEyebrowSize.Medium}
            className='text-interface-500'
          >
            {title}
          </Eyebrow>
        </div>
      )}
      {description && (
        <div className='w-full py-6 text-sm leading-[1.488rem] text-interface-800 xl:max-w-[26rem]'>
          {description}
        </div>
      )}
      {socialMedia && (
        <SocialMediaList companyLinks={socialMedia} iconStyle={iconStyle} />
      )}
    </div>
  );
};
