import {
  ESocialMediaIconStyle,
  ESocialMediaType,
  isRichTextContentEmpty,
  RichText,
} from '@front/shared/ds';
import { TFooterV2Props } from '@front/shared/ds/FooterV2';

import type { TFooterWithSitemapConnectedConnectedProps } from './FooterV2Connected';

export const prepareSocialMedia = ({
  socials,
  iconStyle,
}: Pick<TFooterWithSitemapConnectedConnectedProps, 'socials' | 'iconStyle'>):
  | TFooterV2Props['socials']
  | null => {
  let socialMedia = socials?.socialMedia;
  if (socials?.socialMedia && socials?.socialMediaIconsOrder?.order) {
    socialMedia = socials.socialMediaIconsOrder.order.split(' ').reduce(
      (acc, name) => {
        if (socials.socialMedia) {
          acc[name] = socials.socialMedia[name as ESocialMediaType] ?? '';
        }
        return acc;
      },
      {} as { [key: string]: string },
    );
  }

  const socialsPrepared = socials
    ? {
        ...socials,
        description:
          !!socials.description &&
          !isRichTextContentEmpty(socials.description) ? (
            <RichText content={socials.description} />
          ) : null,
        iconStyle: iconStyle as ESocialMediaIconStyle,
        socialMedia,
      }
    : null;

  return socialsPrepared;
};
