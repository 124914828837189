import clsx from 'clsx';
import Link from 'next/link';
import React, { DetailedHTMLProps } from 'react';

import SupportCard, { TSupportCardProps } from './SupportCard';
import { EActionType, TAction } from '../../../types';

export type TSupportCardsProps = {
  cards: (Omit<TSupportCardProps, 'className'> & { action: TAction | null })[];
  className?: string;
};

const SupportCards: React.FC<TSupportCardsProps> = ({ cards, className }) => {
  return (
    <div
      className={clsx(
        'mx-auto inline-flex items-start justify-start gap-6 md:mx-0',
        className,
      )}
    >
      {cards.map(({ action, ...card }, index) => (
        <React.Fragment key={`${card.title}-${index}`}>
          {action?.type === EActionType.ButtonType && (
            <button
              {...(action.props as DetailedHTMLProps<
                React.ButtonHTMLAttributes<HTMLButtonElement>,
                HTMLButtonElement
              >)}
            >
              <SupportCard {...card} />
            </button>
          )}
          {action?.type === EActionType.LinkType && (
            <Link {...action.props}>
              <SupportCard {...card} />
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SupportCards;
